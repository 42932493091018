import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayButton = _resolveComponent("PlayButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlayButton, {
      class: "mr-auto",
      text: _ctx.$t('navigation.next_page'),
      arrowType: "back",
      textFirst: false,
      disabled: !_ctx.hasNextPage,
      onClick: _ctx.next
    }, null, 8, ["text", "disabled", "onClick"]),
    _createVNode(_component_PlayButton, {
      class: "ml-auto",
      text: _ctx.$t('navigation.previous_page'),
      arrowType: "forward",
      textFirst: true,
      disabled: !_ctx.hasPreviousPage,
      onClick: _ctx.previous
    }, null, 8, ["text", "disabled", "onClick"])
  ]))
}