import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-5"
}
const _hoisted_2 = {
  key: 1,
  class: "p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SmallButton = _resolveComponent("SmallButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.$t('print_edition.corcordance_analysis')
  }, {
    default: _withCtx(() => [
      (typeof _ctx.selectedWord !== 'undefined')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.analyses, (analysis) => {
              return (_openBlock(), _createElementBlock("p", {
                class: "break-all",
                key: analysis
              }, _toDisplayString(analysis), 1))
            }), 128)),
            _createVNode(_component_SmallButton, { class: "bg-green-200 mt-3 block ml-auto mr-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: {
            name: 'VerseConcordance',
            params: { word: _ctx.selectedWord + 1 },
          }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('print_edition.to_concordance')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('print_edition.parse_message')), 1))
    ]),
    _: 1
  }, 8, ["title"]))
}