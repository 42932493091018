
import { computed, defineComponent } from 'vue';
import PlayButton from '@/components/global/PlayButton.vue';
import { nextPage, previousPage } from '@/components/print/cairo_edition_url';

export default defineComponent({
  components: { PlayButton },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  emits: ['newImage'],
  setup(props, context) {
    const hasNextPage = computed(() => !!nextPage(props.imageUrl));
    const hasPreviousPage = computed(() => !!previousPage(props.imageUrl));

    function next() {
      if (hasNextPage.value) {
        context.emit('newImage', nextPage(props.imageUrl));
      }
    }

    function previous() {
      if (hasPreviousPage.value) {
        context.emit('newImage', previousPage(props.imageUrl));
      }
    }

    return {
      hasNextPage,
      hasPreviousPage,
      next,
      previous,
    };
  },
});
