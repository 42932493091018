import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "lg:flex"
}
const _hoisted_2 = { class: "lg:pl-3 flex-grow w-full" }
const _hoisted_3 = { class: "text-2xl p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_VerseBar = _resolveComponent("VerseBar")!
  const _component_CairoQuran = _resolveComponent("CairoQuran")!
  const _component_ArabicText = _resolveComponent("ArabicText")!
  const _component_Transcription = _resolveComponent("Transcription")!
  const _component_WordAnalysis = _resolveComponent("WordAnalysis")!
  const _component_Card = _resolveComponent("Card")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!
  const _component_RemoteContent = _resolveComponent("RemoteContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('print_edition.page_title'),
      infoText: _ctx.printWeb.payload?.introduction_general
    }, null, 8, ["title", "infoText"]),
    _createVNode(_component_VerseBar),
    _createVNode(_component_RemoteContent, { data: _ctx.printData }, {
      default: _withCtx(() => [
        (_ctx.isLoaded(_ctx.printData))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_CairoQuran),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ArabicText, {
                  arabicTextInfo: _ctx.printWeb.payload?.arabic_text_info,
                  arabicVerse: _ctx.printData.payload.arabic_text,
                  selectedWord: _ctx.selectedWord,
                  onWordSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedWord = $event))
                }, null, 8, ["arabicTextInfo", "arabicVerse", "selectedWord"]),
                _createVNode(_component_Transcription, {
                  transcriptionInfo: _ctx.printWeb.payload?.transcription_info,
                  transcription: _ctx.printData.payload.transcription_text,
                  selectedWord: _ctx.selectedWord,
                  onWordSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedWord = $event))
                }, null, 8, ["transcriptionInfo", "transcription", "selectedWord"]),
                _createVNode(_component_WordAnalysis, {
                  selectedWord: _ctx.selectedWord,
                  analyses: _ctx.printData.payload.transcription_analysis[_ctx.selectedWord]
                }, null, 8, ["selectedWord", "analyses"]),
                _createVNode(_component_Card, {
                  title: _ctx.$t('print_edition.translation_header'),
                  informationText: _ctx.printWeb.payload?.translation_info
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.printData.payload.translation), 1)
                  ]),
                  _: 1
                }, 8, ["title", "informationText"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isLoaded(_ctx.printWeb))
          ? (_openBlock(), _createBlock(_component_HowToQuote, {
              key: 1,
              citation: _ctx.printWeb.payload.how_to_cite
            }, null, 8, ["citation"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}