import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UrlBackAndForth = _resolveComponent("UrlBackAndForth")!
  const _component_OpenSeaDragonViewer = _resolveComponent("OpenSeaDragonViewer")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.printWeb.payload?.image_description_short,
    subtitle: _ctx.pageInfo,
    informationText: _ctx.printWeb.payload?.image_description_long
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UrlBackAndForth, {
        imageUrl: _ctx.imageUrl,
        onNewImage: _ctx.setImageUrl
      }, null, 8, ["imageUrl", "onNewImage"]),
      _createVNode(_component_OpenSeaDragonViewer, {
        iiifJsonUrls: [_ctx.imageUrl],
        image_links: [null],
        image_sources: [null]
      }, null, 8, ["iiifJsonUrls"])
    ]),
    _: 1
  }, 8, ["title", "subtitle", "informationText"]))
}